import React, { useState, useEffect } from "react";
import { CommonActions } from "@react-navigation/native";
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Image,
  Modal,
} from "react-native";
import { FlatList } from "react-native-gesture-handler";
import Swiper from "react-native-web-swiper";
import { useNavigation } from "@react-navigation/native";
import { useSelector } from "react-redux";
import Axios from "axios";
import Tile from "./../components/Tile";
import { createStackNavigator } from "@react-navigation/stack";
import AwesomeAlert from "react-native-awesome-alerts";
import { FlatGrid, SectionGrid } from "react-native-super-grid";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { nameValidator } from "./../helpers/nameValidator";
import {
  Portal,
  Button,
  Provider,
  Card,
  IconButton,
  Colors,
} from "react-native-paper";
import QrModel from "../components/QrModel";
import { useDispatch } from "react-redux";
import { setLogout } from "../store/actions/userAction";
import TextInput from "../components/TextInput";
const Stack = createStackNavigator();

const Home = (props) => {
  const APP_VERSION = 1;
  const [user_id, setuser_id] = useState("");
  const [showAlert, setshowAlert] = useState({ id: 0, status: false });
  const [diffVersionAlert, setdiffVersionAlert] = useState(false);
  const [showMsg, setshowMsg] = useState({ title: "", msg: "", status: false });

  const navigation = useNavigation();
  const [OutletDetail, setOutletDetail] = useState([]);
  useEffect(() => {
    Axios.get("https://shelldanlabs.com:3006/api/getOutletDetail").then(
      (responce) => {
        setOutletDetail(responce.data);
      }
    );
  }, []);

  const { userid } = useSelector((state) => state.userInfo);
  useEffect(() => {
    setuser_id(userid);
    Axios.get("https://shelldanlabs.com:3006/api/getSetting").then(
      (responce) => {
        if (responce.data[0].AppVersion != APP_VERSION) {
          setdiffVersionAlert(true);
        }
      }
    );
  }, [userid]);
  const hideAlert = () => {
    setshowAlert(false);
  };
  const showAlertDialog = () => {
    setshowAlert(true);
  };
  const LogoutApp = () => {
    // dispatch(setLogout(true));
    AsyncStorage.clear();
    // props.navigation.replace("Login");
    setdiffVersionAlert(false);
    navigation.reset({
      index: 0,
      routes: [{ name: "Login" }],
    });
  };

  return (
    <View style={{ flex: 1 }}>
      <View style={{ width: "100%", height: "30%" }}>
        <Swiper loop={true} timeout={7} controlsEnabled={false}>
          <Image
            source="https://shelldan.com/qrapp/app_slider/mgm.png"
            style={style.ImageStyle}
          />
          <Image
            source="https://shelldan.com/qrapp/app_slider/mgm1.png"
            style={style.ImageStyle}
          />
          <Image
            source="https://shelldan.com/qrapp/app_slider/mgm2.png"
            style={style.ImageStyle}
          />
        </Swiper>
      </View>
      <View>
        <Text style={style.heading}>Outlet Menu</Text>
      </View>

      <FlatList
        itemDimension={130}
        data={OutletDetail}
        // staticDimension={300}
        // fixed
        spacing={10}
        renderItem={({ item }) => (
          <TouchableOpacity
            onPress={() => {
              navigation.dispatch(
                CommonActions.navigate({
                  name: "MenuListOnOutlet",
                  params: {
                    tId: item.id,
                  },
                })
              );
            }}
          >
            <View style={style.itemContainer}>
              <Image
                source={require("./../assets/gangamahal_logo.png")}
                style={style.ImageStyle}
              />
              <View style={style.ContainerStyle}>
                <Text style={style.TextStyle}>{item.Outlet_name}</Text>
              </View>
            </View>
          </TouchableOpacity>
        )}
      />
      <View>
        <Text>&nbsp;</Text>
        <Text>&nbsp;</Text>
        <Text>&nbsp;</Text>
        <Text>&nbsp;</Text>
      </View>
      <AwesomeAlert
        show={diffVersionAlert}
        showProgress={false}
        title="Old Version"
        message="You are using Old Version. Please Update App..."
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={true}
        confirmText="Ok, I Will Update It..."
        confirmButtonColor="#DD6B55"
        onConfirmPressed={LogoutApp}
      />
      <AwesomeAlert
        show={showMsg.status}
        showProgress={false}
        title={showMsg.title}
        message={showMsg.msg}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={true}
        confirmText="Ok, Thanks..."
        confirmButtonColor="#DD6B55"
        onConfirmPressed={() => {
          setshowMsg({ title: "", msg: "", status: false });
        }}
      />
    </View>
  );
};

// const Home = ({ navigation }) => {
//   return (
//     <Stack.Navigator
//       screenOptions={{
//         headerShown: false,
//       }}
//     >
//       <Stack.Screen
//         name="HomeScreen"
//         component={Home_view}
//         screenOptions={{
//           headerShown: false,
//         }}
//       />
//     </Stack.Navigator>
//   );
// };

export default Home;

const style = StyleSheet.create({
  heading: {
    backgroundColor: "#00A6A6",
    color: "#1E1014",
    fontWeight: "bold",
    fontSize: 20,
    textAlign: "center",
    paddingVertical: 5,
    marginTop: 5,
  },
  gridView: {
    marginTop: 10,
    flex: 1,
  },
  ImageStyle: {
    flex: 1,
    resizeMode: "contain",
    justifyContent: "flex-start",
  },
  itemContainer: {
    backgroundColor: "#4f1601",
    borderRadius: 10,
    padding: 10,
    flexDirection: "column",
    height: 130,
    margin: 5,
  },
  ContainerStyle: {
    textAlign: "center",
    alignContent: "center",
  },
  TextStyle: {
    color: "#ffffff",
    fontSize: 30,
  },
});
